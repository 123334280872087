'use strict';

(function(global, jQuery, Settings) {
    
    function configure() {
        global.addEventListener('hashchange', function() {
            global.location.reload();
        }, false);
    }

    function Ginkgo() {}

    /**
     * @private
     * @member _user
     * @memberOf Ginkgo
     * @type { Object }
     */
    var _user;

    /**
     * @member user
     * @memberOf Ginkgo
     * @type { Object }
     */
    Object.defineProperty(Ginkgo.prototype, 'user', {
        get: function() { return _user; },
        set: function(user) { _user = user; }
    });

    /**
     * @static
     * @member OFFSET_TIME
     * @memberOf Ginkgo
     *
     * @typeof { number }
     */
    Object.defineProperty(Ginkgo.prototype, 'OFFSET_TIME', {
        value: 180000
    });

    /**
     * @method serialize
     * @methodOf Ginkgo
     *
     * @param {object} parameters
     *
     * @returns {string}
     */
    Ginkgo.prototype.serialize = function(parameters) {
        return Object
                .keys(parameters)
                .map(function(key) {
                    return key + '=' + parameters[key];
                })
                .join('&');
    };

    /**
     * @method checkToken
     * @methodOf Ginkgo
     *
     * @returns { JQueryPromise }
     */
    Ginkgo.prototype.checkToken = function () {
        var dfr = jQuery.Deferred();
        setTimeout(() => {
            var token = SSOFrameManagerService.getToken();
            if (token) {
                dfr.resolve(token);
            }
        }, 100);
        

        return dfr.promise();
    };

    /**
     * @method findUser
     * @methodOf Ginkgo
     *
     * @returns {JQueryPromise}
     */
    Ginkgo.prototype.findUser = function () {
        //get user from redwood with sso token
        var dfr = jQuery.Deferred();

        var ajaxConfiguration = {
            url: Settings.API + 'user'
        };
        this
            .ajax(ajaxConfiguration)
            .done(function(data) {
                dfr.resolve(data);
            })
            .fail(function(error) {
                dfr.reject(error);
            });

        return dfr.promise();
    };
    /**
     * @method findModule
     * @methodOf Ginkgo
     *
     * @returns {JQueryPromise}
     */
    Ginkgo.prototype.findModule = function () {
        var dfr = jQuery.Deferred();

        var moduleCodeMatch = global.location.hash.match(/(\d{4}\-\d+\-\d+\-\d+\-\d+(\-\d+)?)/),
            versionMatch = global.location.hash.match(/v(\d+)/);

        var moduleCode,
            moduleVersion;

        if (moduleCodeMatch === null) {
            throw new Error ('No valid code found');
        }
        moduleCode = moduleCodeMatch[1];

        if (versionMatch === null) {
            moduleVersion = 'latest';
        } else {
            moduleVersion = versionMatch[1];
        }

        var ajaxConfiguration = {
            url: Settings.API + 'module/' + moduleCode + '/' + moduleVersion
        };
        this
            .ajax(ajaxConfiguration)
            .done(function(data) {
                dfr.resolve(data);
            })
            .fail(function(error) {
                dfr.reject(error);
            });

        return dfr.promise();
    };

    /**
     * @method findPreviousAndNext
     * @methodOf Ginkgo
     *
     * @param { Object} publication
     * @param { Object} module
     *
     * @returns { { previous: string, next: string } }
     */
    Ginkgo.prototype.findPreviousAndNext = function(publication, module) {
        var modules = flattenModulesOf(publication),
            index = modules.indexOf(module.code),
            previous = (index !== 0) ? index - 1: modules.length - 1,
            next = (index !== modules.length - 1) ? index + 1: 0;
        
        return {
            previous: modules[previous],
            next: modules[next]
        };
    };
    
    function flattenModulesOf(publication) {
        var modules = [];
        publication['publication_levels'].forEach(function(theme) {
            if (typeof theme['publication_levels'] === 'undefined') {
                return;
            }

            theme['publication_levels'].forEach(function(chapter) {
                if (typeof chapter['publication_levels'] === 'undefined') {
                    return;
                }

                chapter['publication_levels'].forEach(function(topic) {
                    if(typeof topic.modules === 'undefined') {
                        return;
                    }

                    modules = [].concat.apply(modules, topic.modules.map(function(module) {

                        if (module.code === global.scene.code) {
                            global.scene.slugData = {
                                'publication': publication.slug,
                                'theme': theme.slug,
                                'chapter': chapter.slug,
                                'topic': topic.slug
                            };
                        }

                        return module.code;
                    }));
                });
            });
        });
        
        return modules;
    }

    /**
     * @method load
     * @methodOf Ginkgo
     *
     * @param {Frame|Create} Scope
     *
     * @returns {void}
     */
    Ginkgo.prototype.load = function(Scope) {
        var self = this;

        if (Scope === global.Create || global.location.hash.match(/preview/) !== null) {
            // Do it the old way
            self.loadLegacy(Scope);
            return;
        }
        var scopeInstance = new Scope();
        // check token
        self.checkToken()
        .then(function() {
            configure();
            // find user
            return self.findUser();
        })
        .then(function(user) {
            self.user = user;

            // find module
            return self.findModule();
        })
        .then(function(data) {
            // inject scene into the window
            global.scene = data.module;

            // find previous scene
            var prevnext = self.findPreviousAndNext(data.publication, data.module);

            // inject previous and next scene into the window
            global.previous = prevnext.previous;
            global.next = prevnext.next;

            // render Create Frame
            scopeInstance.renderInto(document.body);
            // insert SSO iframe after body has been overwritten
            SSOFrameManagerService.reIntroduceSSO();
        })
        .fail(function (reason) {
            global.reason = reason;
            console.error(reason);
        });
        
    };

    /**
     * @method loadLegacy
     * @methodOf Ginkgo
     *
     * @returns { void }
     */
    Ginkgo.prototype.loadLegacy = function (Scope) {
        var self = this,
            scopeInstance= new Scope();

        self.isAuthenticated()
            .then(function(authenticated) {
                if (! authenticated) {
                    // redirect towards web-app login
                    var current = global.escape(global.location.href.substr(location.href.indexOf('//')));
                    window.location = Settings.WEBAPP + 'login?redirect=' + current;

                    throw new Error('you have to log in');
                }

                configure();

                return self.findUserLegacy();
            })
            .then(function (user) {
                self.user = user;

                return self.findModuleLegacy();
            })
            .then(function (module) {
                global.scene = JSON.parse(module);
                scopeInstance.renderInto(document.body);
            })
            .fail(function (reason) {
                global.reason = reason;
                console.error(reason);
            })
    };
    /**
     * @method isAuthenticated
     * @methodOf Ginkgo
     *
     * @returns {JQueryPromise}
     */
    Ginkgo.prototype.isAuthenticated = function() {
        var dfr = jQuery.Deferred();

        var ajaxConfig = {
            'url': Settings.WEBAPP + 'api/user/authenticated',
            xhrFields: { withCredentials: true }
        };
        jQuery.ajax(ajaxConfig)
            .done(function(data) {
                dfr.resolve(data.authenticated === 1);
            })
            .fail(function(xhr) {
                dfr.reject(xhr);
            });

        return dfr.promise();
    };

    Ginkgo.prototype.findUserLegacy = function() {
        var dfr = jQuery.Deferred();

        var ajaxConfig = {
            url: Settings.WEBAPP + 'api/user/user',
            xhrFields: { withCredentials: true }
        };

        jQuery.ajax(ajaxConfig)
            .done(function(data) {
                dfr.resolve(data);
            })
            .fail(function(xhr) {
                dfr.reject(xhr);
            });

        return dfr.promise();
    };

    Ginkgo.prototype.findModuleLegacy = function() {
        var dfr = jQuery.Deferred();

        var moduleCodeMatch = global.location.hash.match(/(\d{4}\-\d+\-\d+\-\d+\-\d+(\-\d+)?)/),
            moduleCode;

        if (moduleCodeMatch === null) {
            throw new Error ('No valid code found');
        }
        moduleCode = moduleCodeMatch[1];

        var ajaxConfig = {
            url: Settings.SERVER + 'preview',
            data: { value: moduleCode },
            xhrFields: { withCredentials: true }
        };

        jQuery.ajax(ajaxConfig)
            .done(function(data) {
                dfr.resolve(data);
            })
            .fail(function(xhr) {
                dfr.reject(xhr);
            });

        return dfr.promise();
    };


    /**
     * @method track
     * @methodOf Ginkgo
     * @description sends a pageview event to google analytics
     *
     * @returns {void}
     */
    Ginkgo.prototype.track = function() {
        if(Settings.ENVIRONMENT === 'production') {
            window.ga('create', 'UA-19258261-5', 'auto');
            window.ga('send', 'pageview');
        }
    };

    /**
     * @method ajax
     * @methodOf Ginkgo
     *
     * @param {Object} options
     *
     * @returns {JQueryXHR}
     */
    Ginkgo.prototype.ajax = function(options) {
        
        if (typeof options === 'undefined') {
            options = {};
        }
        if(options.url &&  options.url.indexOf('redwood') !== -1) {
            var token = SSOFrameManagerService.getToken();
            var idToken = SSOFrameManagerService.getIdToken();
            if (token && idToken) {
                if (typeof options.headers === 'undefined') {
                    options.headers = {};
                }
                options.headers['Authorization'] =  'Bearer ' + token;
                options.headers['Idtoken'] =  '' + idToken;            
            }
        }
        return jQuery.ajax(options);
    };

    global.Ginkgo = new Ginkgo();
})(window, $, window.Settings);